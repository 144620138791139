import {defineStore} from 'pinia';
import {ref, computed} from 'vue';
import {getFromServer} from "../composables/getFromServer.js";
import {getFromClient} from "../composables/getFromClient.js";

export const useUserStore = defineStore('user', () => {
    const user = ref([]);

    function initData(data) {
        user.value = data;
    }

    async function fetchUser() {
        user.value = await getFromServer('user');
    }

    async function login(email, password) {
        // Get csrf cookies
        await getFromClient('sanctum/csrf-cookie', {
            credentials: 'include'
        });

        // Send login request with the csrf cookies that we got
        const token = useCookie('XSRF-TOKEN');
        useClientFetch('login', {
            method: 'POST',
            headers: {'X-XSRF-TOKEN': token.value},
            body: {
                email: email,
                password: password
            }
        }).then(response => {
            if (response?.code === 'ok' && response?.success) {
                // Sessions are received. Just refresh the page to make cart/watchlist work
                // useCartStore().fetchCart();
                // useWatchlistStore().fetchWatchlist();
                //profile menu...
                reloadNuxtApp();
            } else {
                alert('Failed!');
            }
        });
    }

    function isLoggedIn() {
        return !!user.value?.id;
    }

    return {
        user,
        initData,
        login,
        isLoggedIn,
        fetchUser
    };
});
